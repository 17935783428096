$grey_bg_color: rgba(250, 250, 250, 255);
$deep_light_yellow_bg_color: rgba(253, 251, 247, 255);
$deep_light_bg_color: rgba(255, 249, 228, 255);
$light_bg_color: rgba(255, 242, 219, 255);
$main_bg_color: rgba(239, 232, 220, 255);
$light_desc_color: rgba(229, 226, 218, 255);
$deep_bg_color: rgba(226, 207, 164, 255);
$main_bg_light_color: rgba(207, 183, 142, 255);
$deep_text_color: rgba(193, 161, 107, 255);
$purple_light_color: rgba(255, 236, 226, 255);
$title_color: rgba(38, 34, 32, 255);
$sub_title_color: rgba(107, 100, 97, 255);
$light_sub_title_color: rgba( 153, 153, 153, 255);
$deep_light_grey_bg_color: rgba( 243, 240, 236, 255);
$deep_light_desc_color: rgba( 245, 243, 240, 255);
$desc_color: rgba( 191, 188, 182, 255);
$line_color: rgba( 238, 238, 238, 255);
$danger_color: rgba( 193, 55, 55, 255);
$light_red_color: rgba( 255, 59, 76, 255);
$green_color: rgba( 55, 193, 107, 255);