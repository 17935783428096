.page-tongue-tips {
    margin: 10px;

    section.group {
        margin: 10px;

        .title {
            display: inline-block;
            background: #c1a16b;
            color: #fff;
            padding: 2px 15px;
            border-radius: 10px 0 10px 0;
        }

        .text {
            margin-top: 10px;
            color: #6b6461;
            line-height: 2em;;
        }
    }
}