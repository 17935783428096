@import "./style";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey_bg_color;

  & > div {
    text-align: center;
    margin-top: 40vh;
    color: $deep_text_color;
  }

  &-icon {
    font-size: 30px;
    color: $deep_text_color;
  }
}

.mask {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(black, 0.7);

  & > div {
    display: flex;
    justify-content: space-between;
    background-color: rgba(white, 0.9);
    align-items: center;
    padding: 10px 20px;

    h4 {
      width: 100%;
      text-align: center;
    }

    img {
      width: 40px;
    }
  }
}

.article-wrap {
  user-select: none;
  font-size: 16px;
  line-height: 1.7em;
  background-color: $grey_bg_color;
  min-height: 100vh;

  .share-wrap {
    padding: 5px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    &-content, .description, .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-content {
      background-color: $deep_light_grey_bg_color;
      border-radius: 15px;
    }

    .description {
      line-height: 1.2em;

      img {
        padding: 10px;
        height: 40px;
        color: $deep_text_color;
      }

      h3 {
        padding: 0;
        font-size: 14px;
      }
    }

    .action {
      margin-right: 10px;
      background-color: $deep_text_color;
      border-radius: 20px;
      padding: 0 10px;
      img {
        width: 14px;
      }

      p {
        color: white;
      }
    }

    p {
      margin: 0;
      font-size: 10px;
      color: $light_sub_title_color;
    }
  }

  &-content {
    padding: 15px 0;
  }

  .image-content {
    padding: 10px 0;
    margin: 10px 0;
    border-radius: 5px;

    &-bg {
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      max-width: 100%;
      visibility: hidden;
    }
  }

  ::selection {
    background: $deep_text_color;
    color: white;
  }

  & > * {
    padding: 0 15px;
  }

  h1, h2, h3, h4, h5, h6, .title {
    margin: 0;
    padding-bottom: 3px;
    color: $title_color;
    font-family: "custom-family", serif;
    font-weight: bold;
  }

  h1, .title {
    font-weight: bold;
    font-size: 22px;
    padding-left: 0.2em;
    padding-bottom: 3px;
    background-size: 0.5em 0.5em;
    background-image: url("images/bg.png");
    background-repeat: no-repeat;
    background-position: 0 0.8em;
  }

  h2 {
    font-size: 20px;

    &::before {
      content: "•";
    }
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 17px;
  }

  .column-title {
    color: #888888;
    font-size: 14px;
  }

  p {
    margin: 5px 0 10px;
    color: $sub_title_color;
  }

  ol, ul {
    margin: 0;
    padding-left: 20px;

    li {
      margin: 5px 0 10px;
      color: $sub_title_color;
    }
  }

  a {
    color: $deep_text_color;
    text-decoration: underline;
    -webkit-tap-highlight-color: transparent;

    &:active, &:hover {
      background: $deep_text_color;
      color: white;
    }
  }
}
